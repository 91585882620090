<script>
import draggable from 'vuedraggable'
import Spinner from '@/components/Spinner.vue';
import ModalCaptureImage from '@/components/customization/modalCaptureImage.vue'
import MdalChangeAlias from '@/components/customization/modalChangeAlias.vue'
import { mapActions, mapState } from "vuex";

export default {
	components: {
		draggable,
		ModalCaptureImage,
		MdalChangeAlias,
		Spinner
	},
	data() {
		return {
			modulo: {},
			icon: {
				height: '340',
				width: '340'
			},
			form: {
				modulos_desactivados: [],
				modulos_activos: []
			},
			show_spinner: false
		}
	},

	watch: {
		listaModulosActivos: {
			handler: function(newArr) {
				// eslint-disable-next-line no-undef
				this.form.modulos_activos = structuredClone(newArr);
			},
			deep: true
		},
		
		listaModulosDesactivados: {
			handler: function(newArr) {
				// eslint-disable-next-line no-undef
				this.form.modulos_desactivados = structuredClone(newArr);
			},
			deep: true
		},
	},
	
	computed: {
		...mapState("customizationModule", ["listaModulosActivos", "listaModulosDesactivados"]),

		dragOptions() {
			return {
				animation: 200,
				ghostClass: "ghost"
			};
		},
		validation() {
			return {
				modulos_activos: this.form.modulos_activos.length >= 5,
				modulos_destacados: 5 >= this.modulos_destacados.length && this.modulos_destacados.length >= 3
			}
		},
		isValid() {
			let validation = this.validation
			return Object.keys(validation).every(keys => validation[keys]);
		},

		modulos_destacados() {
			return this.form.modulos_activos.filter(modulo => modulo.destacado);
		},

		indexOfLastDestacado() {
			return this.form.modulos_activos.findLastIndex(modulo => modulo.destacado);
		}
	},

	mounted() {
		// eslint-disable-next-line no-undef
		this.form.modulos_activos = structuredClone(this.listaModulosActivos);
		// eslint-disable-next-line no-undef
		this.form.modulos_desactivados = structuredClone(this.listaModulosDesactivados);
	},

	methods: {
		...mapActions("customizationModule", ["editModulos"]),

		toggleDestacado(modulo) {
			if (!modulo.destacado) {
				if (this.modulos_destacados.length < 5) {
					modulo.destacado = true;

					const itemIdx = this.form.modulos_activos.indexOf(modulo);
					if (itemIdx !== -1) {
						this.form.modulos_activos.splice(itemIdx, 1);
						this.form.modulos_activos.unshift(modulo);
					}
				}
			} else if (modulo.destacado) {
				modulo.destacado = false;
			}
		},
		capturarModulo(modulo) {
			this.modulo = modulo;
		},

		activarModulo() {
			this.modulo = {};
		},
		captureImage(image) {
			this.modulo.imagen_url = image;
		},
		changeAlias(nombre_alias) {
			this.modulo.nombre_alias = nombre_alias;
		},

		onChangeActivos(action) {
			if (action.added) {
				this.form.modulos_activos[action.added.newIndex].id_estado_fk = 1;
			} 
		},

		onChangeDesactivados(action) {
			if (action.added) {
				this.form.modulos_desactivados[action.added.newIndex].id_estado_fk = 2;

				if (action.added.element.destacado) { // elimina de lista Destacados
					this.form.modulos_desactivados[action.added.newIndex].destacado = false;
				}
			} 
		},

		checkMoveDesactivados(evt) {
			if (evt.draggedContext.futureIndex <= this.indexOfLastDestacado) {
				return false;
			}
		},

		checkMoveActivos(evt) {
			if (evt.relatedContext.list.length === 0 || evt.relatedContext.list.some(m => m.id_estado_fk !== 1)) {
				return true;
			}

			const indexOfFirstNoDestacado = this.indexOfLastDestacado + 1;
			// Colocar un no-destacado arriba de un destacado
			if (!evt.draggedContext.element.destacado) {
				if (evt.draggedContext.futureIndex <= this.indexOfLastDestacado) {
					return false;
				}
			}
			
			// Colocar un destacado abajo de un no-destacado
			if (evt.draggedContext.element.destacado) {
				if (evt.draggedContext.futureIndex >= indexOfFirstNoDestacado) {
					return false;
				}
			}
		},

		async save() {
			if (!this.isValid) {
				this.toastEditModulesInvalid();
				return;
			}
			this.show_spinner = true;

			const modulosOrganizados = this.form.modulos_activos.concat(this.form.modulos_desactivados)
			const data_modulos = [];
			modulosOrganizados.forEach((modulo, idx) => {
				const data_modulo = {
					id_detalle: +modulo.id_detalle,
					nombre_alias: modulo.nombre_alias,
					posicion_pantalla: idx,
					default_icono: modulo.privilegio_modulos.imagen_url,
					id_estado_fk: +modulo.id_estado_fk,
					destacado: !!modulo.destacado
				};

				if (!this.moduleUsesDefaultIcon(modulo)) {
					const icono_modulo = modulo.imagen_url.split(/:|;|,/)[3];
					const formato_icono = modulo.imagen_url.split(/:|;|,/)[1];
					Object.assign(data_modulo, { icono_modulo, formato_icono });
				}

				data_modulos.push(data_modulo);
			})

			const resModulos = await this.editModulos({ data_modulos });
			if (!resModulos) {
				this.toastEditModulesError()
			} else {
				this.toastEditModulesSuccess();
			}

			this.show_spinner = false;
		},

		moduleUsesDefaultIcon(module) {
			return module.imagen_url.includes("c3repo.cl");
		},
		toastEditModulesInvalid() {
			this.$toast.open({
				message: "Hay menos de 5 módulos activos o menos de 3 destacados.",
				type: "error",
				duration: 5000,
				position: "top-right",
			});
		},
		toastEditModulesError() {
			this.$toast.open({
				message: "Error en el envío de información. Por favor recarga la página e intenta nuevamente.",
				type: "error",
				duration: 5000,
				position: "top-right",
			});
		},
		toastEditModulesSuccess() {
			this.$toast.open({
				message: "Se ha editado la configuración de módulos exitosamente.",
				type: "success",
				duration: 5000,
				position: "top-right"
			});
		},
	},

}

</script>

<template>
	<form @submit.prevent="save" style="cursor: auto;">
		<h5 class="mt-3 mt-lg-0 font-main text-secondary">
			<i class="fa-solid fa-list color-main"></i> Gestión de módulos
		</h5>
		<h6 class="font-main text-secondary border-bottom border-color mb-3 pb-3 text-opacity-50">Podrás cambiar el ícono, nombre y activar o desactivar los módulos que necesites.</h6>
		<div class="row">
			<div class="col-12 col-lg-5 col-xxl-3 mb-3 mb-xxl-0">
				<h6 class="text-secondary font-main text-opacity-75">
					<i class="fa-solid fa-toggle-off color-gray"></i> Desactivados
				</h6>
				<draggable class="lw-list-drag lw-list-drag-scroll" style="cursor: auto;" :list="form.modulos_desactivados" :move="checkMoveDesactivados" group="modulos" @change="onChangeDesactivados" v-bind="dragOptions">
					<div class="lw-list-drag-container" v-for="modulo in form.modulos_desactivados" :key="modulo.id_detalle" @mousedown="activarModulo()">
						<div class="lw-list-drag-item justify-content-between">
							<div class="d-flex align-items-center">
								<div class="flex-shrink-0">
									<i class="fa-solid fa-grip-vertical lw-list-drag-item-icon me-2"></i>
								</div>
								<div class="flex-grow-1 lh-1">
									{{ modulo.nombre_alias }}
								</div>
							</div>
							<i class="fa-solid fa-angle-right lw-list-drag-item-icon"></i>
						</div>
					</div>
				</draggable>
			</div>
			<div class="col-12 col-lg-7 col-xxl-5 mb-3 mb-xxl-0">
				<h6 class="text-secondary font-main text-opacity-75">
					<i class="fa-solid fa-toggle-on color-main"></i> Activos
				</h6>
				<draggable class="lw-list-drag lw-list-drag-scroll" :list="form.modulos_activos" :move="checkMoveActivos" group="modulos" @change="onChangeActivos" v-bind="dragOptions">
					<div class="lw-list-drag-container" v-for="modulo in form.modulos_activos" :key="modulo.id_detalle" @mousedown="capturarModulo(modulo)">
						<div class="lw-list-drag-item justify-content-between">
							<div class="d-flex align-items-center">
								<div class="flex-shrink-0">
									<i class="fa-solid fa-grip-vertical lw-list-drag-item-icon me-2"></i>
								</div>
								<div class="flex-grow-1 lh-1">
									<div class="d-flex align-items-center">
										<div class="flex-shrink-0">
											<a href="javascript:" data-bs-toggle="modal" data-bs-target="#modal-capture-image" class="lw-list-drag-item-img" :class="!moduleUsesDefaultIcon(modulo) ? 'lw-list-drag-item-img-custom' : '' ">
												<img :src="modulo.imagen_url ?? modulo.privilegio_modulos.imagen_url" width="40" class="rounded">
											</a>
										</div>
										<div class="flex-grow-1 ms-2">
											{{ modulo.privilegio_modulos.nombre_modulo }}<br/>
											<small class="text-secondary text-opacity-50">
												<i class="fa-solid fa-square-pen font-size-12 me-1"></i>
												<a 
													href="javascript:"
													data-bs-toggle="modal"
													data-bs-target="#modal-change-alias"
													class="lw-list-drag-item-change-alias">
													<template v-if="modulo.nombre_alias">{{ modulo.nombre_alias }}</template>
													<template v-else> Nombre personalizado </template>
												</a>
										</small>
									</div>
								</div>
							</div>
						</div>
						<a @click="toggleDestacado(modulo)" href="javascript:" >
							<span v-if="!modulo.destacado" key="regular-heart" class="lw-list-drag-item-icon">
								<i class="fa-regular fa-heart"></i>
							</span>
							<span v-else key="solid-heart" class="lw-list-drag-item-icon destacado">
								<i class="fa-solid fa-heart"></i>
							</span>
						</a>
					</div>
				</div>
				</draggable>
			</div>
			<div class="col-12 col-xxl-4 mb-3 mb-xxl-0">
				<h6 class="text-secondary font-main text-opacity-75 d-flex justify-content-between">
					<span>
						<i class="fa-solid fa-heart color-red"></i>
						Destacados
					</span>
					<span :class="modulos_destacados.length >= 3 ? 'color-green' : 'color-red' ">					
						{{ modulos_destacados.length }}/5
					</span>
				</h6>
<template v-if="modulos_destacados.length !== 0">
	<draggable class="lw-list-drag lw-list-drag-scroll" :list="modulos_destacados" group="destacados" v-bind="dragOptions" :disable="true">
		<div class="lw-list-drag-container" v-for="modulo in modulos_destacados" :key="modulo.id_detalle">
			<div class="lw-list-drag-item">
				<div class="d-flex align-items-center">
					<div class="flex-shrink-0">
						<i class="fa-solid fa-grip-vertical lw-list-drag-item-icon me-2"></i>
					</div>
					<div class="flex-grow-1 lh-1">
						<div class="d-flex align-items-center">
							<div class="flex-shrink-0">
								<span class="lw-list-drag-item-img-static">
									<img :src="modulo.imagen_url ?? modulo.privilegio_modulos.imagen_url" width="40" class="rounded">
								</span>
							</div>
							<div class="flex-grow-1 ms-2">
								{{ modulo.privilegio_modulos.nombre_modulo }}<br/>
								<small class="text-secondary text-opacity-50">
									<template v-if="modulo.nombre_alias">{{ modulo.nombre_alias }} </template>
									<template v-else> Nombre personalizado </template>
								</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</draggable>
</template>
<template v-if="modulos_destacados.length < 3">
	<div class="alert alert-light text-secondary border border-red p-3 text-center mb-0 h6">
		<i class="fa-solid fa-circle-arrow-left color-red"></i> Debes agregar al menos 3 destacados.
	</div>
</template>
			</div>
		</div>

		<div class="d-flex justify-content-end mt-3">
			<button 
				type="submit" 
				:disabled="!isValid"
				class="btn btn-custom-color-blue rounded-pill mw-100"
			>
				Guardar
			</button>
		</div>


		<ModalCaptureImage
			:img="icon"
			@crop="captureImage"
		/>

		<MdalChangeAlias
			:data="modulo"
			@aliasActualizado="changeAlias"
		/>
    <Spinner v-if="show_spinner" />


	</form>
</template>
