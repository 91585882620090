<script>
	export default {
		props: {
			data: {
				type: Object,
				required: true,
				default: null
			},
		},
		data() {
			return {
				alias: ''
			}
		},
		watch: {
			data() {
				this.alias = this.data.alias;
			}
		},
		methods: {
			actualizar() {
				this.$emit('aliasActualizado', this.alias);
				this.$refs.closeModal.click();
			}
		}
	}
</script>

<template>
	<div 
		id="modal-change-alias"
		class="modal" 
		tabindex="-1"
	>
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title text-secondary">
						<i class="fa-solid fa-list color-secondary me-1"></i>
						{{ data.nombre }}
					</h5>
					<button 
						ref="closeModal"
						type="button" 
						class="modal-custom-close"
						data-bs-dismiss="modal" 
						aria-label="Close">
						<i class="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 mb-3">
							<label class="text-secondary">Alias del módulo (máximo 25 caracteres)</label>
							<input 
								type="text" 
								v-model.trim="alias"
								class="form-control input-custom" 
								maxlength="25"
								placeholder="Ingresa el nuevo nombre"
							/>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button 
						type="button" 
						ref="closeModal"
						class="btn btn-custom-color-white rounded-pill mw-100" 
						data-bs-dismiss="modal">
						<i class="fa-solid fa-angle-left pe-1"></i>
						Volver
					</button>
					<button 
						@click="actualizar()"
						type="button" 
						class="btn btn-custom-color-blue rounded-pill mw-100"
					>
						Actualizar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>