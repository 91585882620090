<script>
	export default {
		props: {
			img: {
				type: Object,
				required: true,
				default: null
			},
		},
		data() {
			return {
				show: false,
				croppieImage: '',
				cropped: null,
				croppieRef: null
			}
		},
		methods: {
			openInputFile() {
				let inputFile = document.getElementById("file");
				if (inputFile) inputFile.click();
			},

			croppie (e) {
				this.show = true;
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;
			
				var reader = new FileReader();
				reader.onload = e => {
					this.$refs.croppieRef.bind({
						url: e.target.result
					});
				};
			
				reader.readAsDataURL(files[0]);
			},

			crop() {
				document.getElementById("file").value = "";
				try {
					let options = {
						type: 'base64',
						size: { width: this.img.width, height: this.img.height },
						format: 'png'
					};
					this.$refs.croppieRef.result(options, output => {
						this.cropped = this.croppieImage = output;
						this.$emit("crop", this.croppieImage, this.img.name);
					});
					this.$refs.closeModal.click();
				}
				catch(error) {
					console.log(error);
				}
				finally {
					this.show = false;
				}
			}
		},
	}
</script>

<template>
	<div id="modal-capture-image" class="modal" tabindex="-1">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title text-secondary">
						<i class="fa-regular fa-image color-secondary me-1"></i>
						Capturar imagen
					</h5>
					<button 
						ref="closeModal"
						type="button" 
						class="modal-custom-close"
						data-bs-dismiss="modal" 
						aria-label="Close">
						<i class="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<input 
						type="file"
						id="file"
						class="d-none"
						accept="image/png"
						@change="croppie"
					>
					<template v-if="!show">
						<a
							href="javascript:"
							@click="openInputFile()"
							class="capture-img">
							<i class="h1 fa-regular capture-img-icon fa-image"></i>
							Elige un archivo
						</a>
					</template>
					<template v-if="show">				
						<vue-croppie 
							ref="croppieRef" 
							:enableOrientation="true" 
							:boundary="{ height: 400 }" 
							:viewport="{ width:img.width, height:img.height, 'type':'square' }"
							:enableResize="false"
						>
						</vue-croppie>
					</template>
				</div>
				<div class="modal-footer">
					<button 
						type="button" 
						ref="closeModal"
						class="btn btn-custom-color-white rounded-pill mw-100" 
						data-bs-dismiss="modal">
						<i class="fa-solid fa-angle-left pe-1"></i>
						Volver
					</button>
					<button 
						@click="crop()"
						type="button" 
						class="btn btn-custom-color-blue rounded-pill mw-100"
					>
						Agregar
					</button>
				</div>
			</div>
		</div>
	</div>
</template>